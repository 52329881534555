<template>
    <v-spa-layout-page data-component="teams-index">
		<template slot="title">{{ $t('reports.header.title') }}</template>
        <template slot="title-button">
            <div class="block md:hidden">
                <filters ref="filters" @updated="updateFilters"></filters>
                <v-spa-submenu-container :container="$spa.css.submenu.container.large" position="right-0" ref="filtersSubmenu">
                    <template v-slot:default="slotProps">
                        <v-spa-loading-button class-name="flex items-center px-4 py-2 text-sm text-gray-600 bg-white border rounded cursor-pointer border-slate-200" @clicked="slotProps.toggle()">
                            <i class="sm:mr-1 far fa-filter" />
                            <span class="hidden sm:block">{{ $t('reports.header.filters') }}</span>
                        </v-spa-loading-button>
                    </template>
                    <template v-slot:submenu>
                        <div class="w-[250px]">
                            <div class="mb-4">
                                <span class="block mb-1 text-sm text-gray-500">{{ $t('reports.header.dates') }}</span>
                                <v-datepicker-internal
                                    v-model="range"
                                    mode="range"
                                    :popover="{ visibility: 'click' }"
                                    :locale="$i18n.locale"
                                    :attributes="[
                                        {
                                            key: 'today',
                                            dot: true,
                                            dates: new Date(),
                                        },
                                    ]"
                                >
                                    <div class="flex justify-center px-4 py-2 text-sm text-gray-600 bg-white border rounded cursor-pointer border-slate-200">
                                        <div>
                                            <span>{{ form.start | moment().format('DD/MM/YYYY') }}</span>
                                            <span class="px-2">—</span>
                                            <span>{{ form.end | moment().format('DD/MM/YYYY') }}</span>
                                        </div>
                                    </div>
                                </v-datepicker-internal>
                            </div>
                            <div class="mb-4">
                                <span class="block mb-1 text-sm text-gray-500">{{ $t('reports.header.view') }}</span>
                                <v-spa-element container-class-name="" :display-label="false" :form="form" input-name="groupBy">
                                    <select :class="$spa.css.form.select" v-model="form.groupBy" @change="refreshReports()">
                                        <option value="day">{{ $t('reports.header.views.day') }}</option>
                                        <option value="week">{{ $t('reports.header.views.week') }}</option>
                                        <option value="month">{{ $t('reports.header.views.month') }}</option>
                                    </select>
                                </v-spa-element>
                            </div>
                        </div>
                    </template>
                </v-spa-submenu-container>
            </div>
            <div class="relative z-20 items-stretch hidden space-x-2 md:flex">
                <filters ref="filters" @updated="updateFilters"></filters>
                <v-datepicker-internal
                    v-model="range"
                    mode="range"
                    :popover="{ visibility: 'click' }"
                    :locale="$i18n.locale"
                    :attributes="[
                        {
                            key: 'today',
                            dot: true,
                            dates: new Date(),
                        },
                    ]"
                >
                    <div class="flex items-center h-full px-4 py-2 text-sm text-gray-600 bg-white border rounded cursor-pointer border-slate-200">
                        <span>{{ form.start | moment().format('DD/MM/YYYY') }}</span>
                        <span class="px-2">—</span>
                        <span>{{ form.end | moment().format('DD/MM/YYYY') }}</span>
                    </div>
                </v-datepicker-internal>
                <div>
                    <div class="flex items-stretch h-full bg-white border rounded border-slate-200">
                        <div class="flex items-center px-4 py-2 text-sm text-gray-600 cursor-pointer" :class="{'bg-gray-100 text-black': form.groupBy == 'day'}" @click.prevent="setGroupBy('day')">
                            <span>{{ $t('reports.header.views.day') }}</span>
                        </div>
                        <div class="flex items-center px-4 py-2 text-sm text-gray-600 border-l border-r cursor-pointer border-slate-200" :class="{'bg-gray-100 text-black': form.groupBy == 'week'}" @click.prevent="setGroupBy('week')">
                            <span>{{ $t('reports.header.views.week') }}</span>
                        </div>
                        <div class="flex items-center px-4 py-2 text-sm text-gray-600 cursor-pointer" :class="{'bg-gray-100 text-black': form.groupBy == 'month'}" @click.prevent="setGroupBy('month')">
                            <span>{{ $t('reports.header.views.month') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        
        <template slot="content">
            <div v-if="loading.base || loading.refresh" class="relative z-10">
                <v-spa-panel :loading="true"></v-spa-panel>
            </div>

            <div class="flex flex-col space-y-4" v-else>
                <v-spa-panel :title="$t('reports.invoiced.label')">
                    <canvas id="global-chart" width="400" height="140"></canvas>
                </v-spa-panel>
                <v-spa-panel :title="$t('reports.hours.label')">
                    <canvas id="per-users-chart" width="400" height="140"></canvas>
                </v-spa-panel>
            </div>
        </template>
    </v-spa-layout-page>
</template>

<script>

    import Chart from 'chart.js/auto';
    import InternalDatePicker from 'v-calendar/lib/components/date-picker.umd';
    import Filters from '~base/components/_Partials/Filters';

    export default {
        components: {
            'filters': Filters,
            'v-datepicker-internal': InternalDatePicker
        },
        data() {
            return {
                loading: {
                    base: true,
                    refresh: false
                },
                form: {
                    start: '',
                    end: '',
                    groupBy: 'day'
                },
                range: {
                    start: '',
                    end: ''
                },
                reports: null,
                charts: {
                    global: null,
                    perUser: null
                }
            }
        },
        watch: {
            'range.start'(date) {
                this.form.start = window.moment(date).format('YYYY-MM-DD');
                this.refreshReports();
            },
            'range.end'(date) {
                this.form.end = window.moment(date).format('YYYY-MM-DD');
                this.refreshReports();
            },
        },
        methods: {
            setGroupBy(groupBy)
            {
                this.form.groupBy = groupBy;
                this.refreshReports();
            },

            refreshReports()
            {
                this.loading.refresh = true;
                this.$nextTick(() => this.getReports());
            },

            updateFilters()
            {
                this.refreshReports();
            },

            getFilters()
            {
                return {...this.form, ...this.$store.state.filters};
            },

            async getReports()
            {
                let data = await this.$store.dispatch('getReports', this.getFilters());

                this.buildReports(data);
            },

            buildReports({start, end, groupBy, reports})
            {
                this.loading.base = this.loading.refresh = false;
                this.form.start = start;
                this.form.end = end;
                this.form.groupBy = groupBy;

                this.$store.dispatch('replaceQueryInUrl', {url: this.$route.path, params: this.getFilters()});

                this.reports = reports;
                this.$nextTick(() => {
                    this.buildGlobalChart();
                    this.buildPerUsersChart();
                });
            },

            buildGlobalChart()
            {
                if ( this.charts.global ) {
                    this.charts.global.destroy();
                }

                var ctx = document.getElementById("global-chart");
                this.charts.global = new Chart(ctx, {
                    type: 'bar',
                    borderColor: 'red',
                    data: {
                        labels: this.reports.global.labels,
                        datasets: [
                            {
                                label: this.$t('reports.graphs.global.labels.not_invoiced'),
                                backgroundColor: '#ced5da',
                                pointBackgroundColor: 'rgba(5, 113, 255, 0.9)',
                                borderWidth: '0',
                                data: this.reports.global.not_invoiced
                            },
                            {
                                label: this.$t('reports.graphs.global.labels.invoiced'),
                                backgroundColor: '#8ac34a',
                                pointBackgroundColor: 'rgba(5, 113, 255, 0.9)',
                                borderWidth: '0',
                                data: this.reports.global.invoiced
                            }
                        ],
                    },
                    options: {
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: function(context) {
                                        return window.timeToHoursAndMinutes(context.parsed.y);
                                    }
                                }
                            }
                        },
                        scales: {
                            x: {
                                stacked: true,
                            },
                            y: {
                                stacked: true
                            }
                        }
                    }
                });
            },

            buildPerUsersChart()
            {
                if ( this.charts.perUser ) {
                    this.charts.perUser.destroy();
                }

                let datasets = [];
                for ( let key in this.reports.per_user.users ) {
                    datasets.push({
                        label: this.$store.state.models.users.find(u => u.id == key).getName(),
                        backgroundColor: this.reports.per_user.users[key].background_color,
                        pointBackgroundColor: 'rgba(5, 113, 255, 0.9)',
                        borderWidth: '0',
                        data: this.reports.per_user.users[key].data
                    });
                }

                var ctx = document.getElementById("per-users-chart");
                this.charts.perUser = new Chart(ctx, {
                    type: 'bar',
                    borderColor: 'red',
                    data: {
                        labels: this.reports.per_user.labels,
                        datasets: datasets
                    },

                    options: {
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: function(context) {
                                        return window.timeToHoursAndMinutes(context.parsed.y);
                                    }
                                }
                            }
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero:true
                                },
                                stacked: true
                            }]
                        }
                    }
                });
            }
        },
        created() {
            // this.$store.commit('setSidebarSection', 'reports');

            if ( this.$route.query.start ) {
                this.form.start = this.$route.query.start;
                this.range.start = window.moment(this.form.start).toDate();
            }
            if ( this.$route.query.end ) {
                this.form.end = this.$route.query.end;
                this.range.end = window.moment(this.form.end).toDate();
            }
            if ( this.$route.query.groupBy ) {
                this.form.groupBy = this.$route.query.groupBy;
            }

            this.getReports();
        },
        beforeDestroy()
        {
            this.$store.commit('resetFilters');
        }
    }
</script>