
export default {
    loadingApplication: true,
    token: null,
    sidebarSection: null,
    apps: null,
    locale: 'fr',
    layout: {
        header: true,
        sidebar: true,
        container: true,
        intercom: true
    },
    openedModals: [],
    google: null,
    user: null,
    models: {
        account: [],
        users: [],
        worksites: [],
    },
    filters: {
        users: null,
        worksites: null
    }
};