import i18n from "~base/_i18n";

export default {

    /**
     * Set the current user.
     *
     * @param {Object} state Vuex's state
     * @param {Object} user
     */
    setUser(state, user) {
         state.user = user;
        
        if ( user && window.Intercom ) {
            window.intercomSettings.user_id = user.id;
            window.intercomSettings.user_hash = user.intercom_hash;
            window.intercomSettings.name = user.first_name + ' ' + user.last_name;
            window.intercomSettings.email = user.email;
            window.intercomSettings.created_at = user.created_at;
            window.Intercom('update', window.intercomSettings); 
        }
    },

    // /**
    //  * Set the current token.
    //  *
    //  * @param {Object} state Vuex's state
    //  * @param {Object} token
    //  */
    // setToken(state, token) {
    //     state.token = token;
    //     window.axios.defaults.headers.common['Authorization'] = state.token.token_type + ' ' + state.token.access_token;
    // },

    /**
     * Remove the token from the localstorage.
     *
     * @param {Object} state Vuex's state
     */
    removeToken(state)
    {
        state.token = null;
        window.axios.defaults.headers.common['Authorization'] = null;
    },

    /**
     * Set the account.
     *
     * @param {Object} state Vuex's state
     * @param {Object} account
     */
    'ACCOUNT/SET'(state, account) {
        state.account = account;

        // window.axios.defaults.headers.common['X-ACCOUNT-UUID'] = account ? account.uuid : null;

        if ( account && window.Intercom ) {
            window.intercomSettings.timetracker_account = account.name;
            window.intercomSettings.timetracker_account_uuid = account.uuid;
            window.intercomSettings.timetracker_account_created_at = account.created_at;
            window.Intercom('update', window.intercomSettings);
        }
    },

    /**
     * Set the sidebar section of the app.
     * 
     * @param {Object} state Vuex's state
     * @param {String} section
     */
    setSidebarSection(state, section) {
        state.sidebarSection = section;
    },

    /**
     * Set the status of the application loading.
     * 
     * @param {Object}  state Vuex's state
     * @param {Boolean} status
     */
    setApplicationLoadingStatus(state, status) {
        state.loadingApplication = status;
    },

    setLocale(state, locale) {
        state.locale = locale;
        i18n.locale = locale;
        window.moment.locale(locale);
    },

    setUsers(state, users) {
        state.models.users = users;
    },

    setWorksites(state, worksites) {
        state.models.worksites = worksites;
    },
    
    setGoogle(state, google) {
        state.google = google;
    },

    resetFilters(state) {
        state.filters.users = [];
        state.filters.worksites = [];
    },

    setFiltersUsers(state, users) {
        state.filters.users = users;
    },

    setFiltersWorksites(state, worksites) {
        state.filters.worksites = worksites;
    },
    
    addOpenedModal(state, modal) {
        state.openedModals.unshift(modal);
    },

    moveOpenedModalToTop(state, modal) {
        let index = state.openedModals.indexOf(modal);
        console.log(index);

        if ( index > 0 ) {
            state.openedModals.splice(index, 1);
            state.openedModals.unshift(modal);
        }
    },

    removeOpenedModal(state, modal) {
        let index = state.openedModals.indexOf(modal);
        if ( index > -1 ) {
            state.openedModals.splice(index, 1);
        }
    }
};